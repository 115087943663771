/* eslint-disable no-undef, react/prop-types */
import React from 'react';
import { compose } from 'recompose';
import styledSystem from '@emotion/styled';
import { graphql, navigatem, Link } from 'gatsby';
import LayoutWithAuth from '../components/layoutWithAuth';
import { withAuthorization, withEmailVerification } from '../components/Session';
import * as ROLES from '../constants/roles';
import { OrderAdmin } from '../components/OrdersAdmin';
import { Box, Flex } from '../style/basicStyle';
import toast from '../utils/toast';

const OrdersPageAdminBase = (props) => {
  return (
    <Box px={[2, 3, 5]}>
      <h1>Orders</h1>
      <OrderAdmin />
      <button
        style={{ marginRight: '10px' }}
        type="button"
        onClick={() => {
          window.open('https://app.snipcart.com/dashboard/orders');
        }}
      >
        Manage Orders
      </button>
      <button
        style={{ paddingLeft: '10px' }}
        type="button"
        onClick={() => {
          // eslint-disable-next-line react/prop-types
          const { firebase } = props;
          try {
            // eslint-disable-next-line react/prop-types
            firebase.auth.currentUser
              // eslint-disable-next-line react/prop-types
              .getIdToken(/* forceRefresh */ true)
              .then(function getIdToken(idToken) {
                const getCSV = true;
                const adminRequest = true;
                const values = { idToken, adminRequest, getCSV };
                fetch('/.netlify/functions/getOrders', {
                  method: 'POST',
                  headers: { 'Content-Type': 'application/json' },
                  body: JSON.stringify(values)
                })
                  .then((result) => {
                    try {
                      const resultJSON = result.json();
                      resultJSON
                        .then(function processResult(r) {
                          if (result.status === 200) {
                            window.open(r.message, ' Download');
                          } else {
                            console.log('order request ERROR 1 - submitted values: ', values, ' | error is: ', r);
                            toast(`Error 1 downloading list - response code: ${JSON.stringify(r)}`);
                          }
                        })
                        .catch((error) => {
                          console.log('order request ERROR 2 - submitted values: ', values, ' | error is: ', error);
                          toast(`Error 2 downloading list - error is: ${JSON.stringify(error.message)}`);
                        });
                    } catch (e) {
                      console.log('order request ERROR 22 - submitted values: ', values, ' | res is: ', result, ' | error is: ', e);
                      toast(`Error 3 downloading list - error is: ${JSON.stringify(e.message)}`);
                    }
                  })
                  .catch((error) => {
                    console.log('order request ERROR 3 - submitted values: ', values, ' | error is: ', error);
                    toast(`Error 4 downloading list - error is: ${JSON.stringify(error.message)}`);
                  });
              })
              .catch(function getIdTokenError(error) {
                console.log('order - getIdTokenError - error is: ', error);
                toast(`Error downloading list - error is: ${JSON.stringify(error)}`);
              });
          } catch (e) {
            console.log('order - uncaught error - error is: ', e);
            toast(`Error downloading list - error is: ${JSON.stringify(e.message)}`);
          }
        }}
      >
        Download Orders
      </button>
    </Box>
  );
};

const condition = (authUser) => authUser && (!!authUser.roles[ROLES.ADMIN] || !!authUser.roles[ROLES.ORDERS]);

const OrdersPage = compose(withEmailVerification, withAuthorization(condition))(OrdersPageAdminBase);

const Named = ({ toggleNavDark, location }) => {
  return (
    <Box py={[5]}>
      <LayoutWithAuth navDark toggleNavDark={toggleNavDark}>
        <OrdersPage />
      </LayoutWithAuth>
    </Box>
  );
};

export default Named;

export const pageQuery = graphql`
  query ordersAdminContentQuery {
    pageData: allPageDataJson(filter: { name: { eq: "ordersAdmin" } }) {
      edges {
        node {
          name
          title
          subtitle
          subtitle2
        }
      }
    }
  }
`;
