/* eslint-disable react/destructuring-assignment,react/prop-types, no-underscore-dangle, react/jsx-one-expression-per-line, no-param-reassign */
import React, { Component } from 'react';
import styled from '@emotion/styled';
import { usePagination, useSortBy, useTable, useFilters, useGlobalFilter } from 'react-table';
import {
  GlobalFilter,
  DefaultColumnFilter,
  SelectColumnFilter,
  SliderColumnFilter,
  NumberRangeColumnFilter,
  fuzzyTextFilterFn,
  getHiddenColumns, IndeterminateCheckbox
} from "../TableFilters/TableFilters";
import { navigate, Link } from 'gatsby';
import { css } from '@emotion/react';
import toast from '../../utils/toast';
import { Box } from '../../style/basicStyle';
import { withFirebase } from '../Firebase';
import feather from '../../utils/feather';
import { TableStyles, IconInline } from '../../style/tableStyling.js'


// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = val => !val

function Table({ columns, data, initialState }) {
  const filterTypes = React.useMemo(
    () => ({
      // Add a new fuzzyTextFilterFn filter type.
      fuzzyText: fuzzyTextFilterFn,
      // Or, override the default text filter to use
      // "startWith"
      text: (rows, id, filterValue) => {
        return rows.filter(row => {
          const rowValue = row.values[id]
          return rowValue !== undefined
            ? String(rowValue)
              .toLowerCase()
              .startsWith(String(filterValue).toLowerCase())
            : true
        })
      },
    }),
    []
  )

  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
    }),
    []
  )
  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    state: { pageIndex, pageSize, globalFilter },
    visibleColumns,
    preGlobalFilteredRows,
    setGlobalFilter,
    page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page

    // The rest of these things are super handy, too ;)
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    getToggleHideAllColumnsProps,
    allColumns
  } = useTable(
    {
      columns,
      data,
      defaultColumn, // Be sure to pass the defaultColumn option
      filterTypes,
      initialState: {
        pageIndex: 0,
        pageSize: 10,
        hiddenColumns: getHiddenColumns(columns),
        sortBy: [
          {
            id: 'completionDate',
            desc: true
          },
        ]
      }
    },
    useFilters, // useFilters!
    useGlobalFilter, // useGlobalFilter!
    useSortBy,
    usePagination,
  );


  // Render the UI for your table
  return (
    <>
      <div>
        <div>
          <IndeterminateCheckbox {...getToggleHideAllColumnsProps()} />
        </div>
        {/* Loop through columns data to create checkbox */}
        {allColumns.map((column) => (
          <div className="cb action" key={column.id}>
            <label>
              <input type="checkbox" {...column.getToggleHiddenProps()} />{" "}
              <span>{column.Header}</span>
            </label>
          </div>
        ))}
        <br />
      </div>
      <table {...getTableProps()}>
        <thead>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <th {...column.getHeaderProps()}>
                  <span  {...column.getSortByToggleProps()}>
                    {column.render("Header")}
                    <span>{column.disableSortBy ? "" : column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : ''}</span>
                  </span>
                <div>{column.canFilter ? column.render('Filter') : null}</div>
              </th>
            ))}
          </tr>
        ))}
        <tr>
          <th
            colSpan={visibleColumns.length}
            style={{
              textAlign: 'left',
            }}
          >
            <GlobalFilter
              preGlobalFilteredRows={preGlobalFilteredRows}
              globalFilter={globalFilter}
              setGlobalFilter={setGlobalFilter}
            />
          </th>
        </tr>
        </thead>
        <tbody {...getTableBodyProps()}>
        {page.map((row, i) => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map((cell) => {
                return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>;
              })}
            </tr>
          );
        })}
        </tbody>
      </table>
      <Box my={3}>
        <button type="button" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
          {'<<'}
        </button>{' '}
        <button type="button" onClick={() => previousPage()} disabled={!canPreviousPage}>
          {'<'}
        </button>{' '}
        <button type="button" onClick={() => nextPage()} disabled={!canNextPage}>
          {'>'}
        </button>{' '}
        <button type="button" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
          {'>>'}
        </button>{' '}
        <span>
          Page{' '}
          <strong>
            {pageIndex + 1} of {pageOptions.length}
          </strong>{' '}
        </span>
        <span>
          | Go to page:{' '}
          <input
            type="number"
            defaultValue={pageIndex + 1}
            onChange={(e) => {
              const pageGoto = e.target.value ? Number(e.target.value) - 1 : 0;
              gotoPage(pageGoto);
            }}
            style={{ width: '100px' }}
          />
        </span>{' '}
        <select
          value={pageSize}
          onChange={(e) => {
            setPageSize(Number(e.target.value));
          }}
        >
          {[5, 10, 20].map((pageSizeNew) => (
            <option key={pageSizeNew} value={pageSizeNew}>
              Show {pageSizeNew}
            </option>
          ))}
        </select>
      </Box>
    </>
  );
}

// Define a custom filter filter function!
function filterGreaterThan(rows, id, filterValue) {
  return rows.filter(row => {
    const rowValue = row.values[id]
    return rowValue >= filterValue
  })
}

// This is an autoRemove method on the filter function that
// when given the new filter value and returns true, the filter
// will be automatically removed. Normally this is just an undefined
// check, but here, we want to remove the filter if it's not a number
filterGreaterThan.autoRemove = val => typeof val !== 'number'

const orderRecord = (order, idToken, authUser) => {
  return {
    idToken,
    authUser,
    invoiceNumber: order.invoiceNumber,
    status: order.status,
    grandTotal: order.grandTotal,
    trackingNumber: order.trackingNumber,
    completionDate: order.completionDate,
    updatedAtSort: order.updatedAtSort,
    docId: order.docId,
    itemsCount: order.itemsCount,
    email: order.email,
    shippingAddressName: order.shippingAddressName,
    billingAddressName: order.billingAddressName,
    token: order.token,
    name: order.shippingAddressName || order.billingAddressName
  };
};

const columns = [
  {
    Header: 'Orders',
    columns: [
      {
        Header: 'Invoice #',
        accessor: 'invoiceNumber'
      },
      {
        Header: 'Email',
        accessor: 'email'
      },
      {
        Header: 'Name',
        accessor: 'name'
      },
      {
        Header: 'Status',
        accessor: 'status',
        Filter: SelectColumnFilter,
        filter: 'includes',
      },
      {
        Header: 'Tracking #',
        accessor: 'trackingNumber',
        disableSortBy: true,
        Cell: ({ row: { original } }) => {
          let { trackingNumber } = original;
          try {
            const { trackingUrl } = original;
            trackingNumber = trackingUrl && (
              <a rel="noreferrer" target="_blank" href={trackingUrl}>
                {trackingNumber}
              </a>
            );
          } catch (e) {
            console.log('ordersAdmin - CELL error: ', e);
          }
          return trackingNumber || original.trackingNumber;
        }
      },
      {
        Header: 'QTY',
        accessor: 'itemsCount',
        disableFilters: true,
      },
      {
        Header: 'Total',
        accessor: 'grandTotal',
        disableFilters: true,
        Cell: ({ row: { original } }) => {
          const { grandTotal } = original;
          return (grandTotal && `$ ${grandTotal}`) || null;
        }
      },
      {
        Header: 'Date',
        accessor: 'completionDate',
        disableFilters: true,
        Cell: ({ row: { original } }) => {
          let { completionDate } = original;
          try {
            const completionDateArray = completionDate.split('T')[0].split('-');
            completionDate = `${completionDateArray[1]}/${completionDateArray[2]}/${completionDateArray[0]}`;
          } catch (e) {} // we dont really care about errors here...
          return completionDate;
        }
      },
      {
        Header: 'Action',
        accessor: 'docId',
        disableSortBy: true,
        disableFilters: true,
        Cell: ({ row: { original } }) => {
          const { docId } = original;
          const { token } = original;
          const { authUser } = original;
          const action = docId && (
            <IconInline>
              <Link target="_blank" state={{ admin: true }} to={`/viewOrder/${docId}`}>
                {feather('eye', ['25', '25'])}
              </Link>
              {authUser && authUser.roles && authUser.roles.ADMIN && (<a rel="noreferrer" target="_blank" href={`https://app.snipcart.com/dashboard/orders/${token}`}>{feather('edit', ['25', '25'])}</a>)}
            </IconInline>
          );
          return action || null;
        }
      }
    ]
  }
];

class OrdersAdmin extends Component {
  _initFirebase = false;

  constructor(props) {
    super(props);

    this.state = {
      idToken: undefined,
      loading: false,
      OrdersList: []
    };
  }

  componentDidMount() {
    this.getOrdersList();
  }

  componentDidUpdate() {
    this.getOrdersList();
  }

  getOrdersList = () => {
    if (this.props.firebase && !this._initFirebase) {
      const self = this;
      this._initFirebase = true;

      this.setState({ loading: true });

      this.props.firebase.auth.onAuthStateChanged((authUser) => {
        if (authUser) {
          this.props.firebase.auth.currentUser
            // eslint-disable-next-line react/prop-types
            .getIdToken(/* forceRefresh */ true)
            .then(function getIdToken(idToken) {
              const values = { idToken, adminRequest: true };
              fetch('/.netlify/functions/getOrders', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(values)
              })
                .then((result) => {
                  try {
                    const resultJSON = result.json();
                    resultJSON
                      .then(function processResult(r) {
                        if (result.status === 200) {
                          self.setState({
                            // eslint-disable-next-line react/no-unused-state
                            OrdersList: r.message,
                            loading: false,
                            idToken
                          });
                        } else {
                          console.log('order request ERROR 1 - submitted values: ', values, ' | error is: ', r);
                          toast(`Error 1 downloading list - response code: ${JSON.stringify(r)}`);
                        }
                      })
                      .catch((error) => {
                        console.log('order request ERROR 2 - submitted values: ', values, ' | error is: ', error);
                        toast(`Error 2 downloading list - error is: ${JSON.stringify(error.message)}`);
                      });
                  } catch (e) {
                    console.log('order request ERROR 22 - submitted values: ', values, ' | res is: ', result, ' | error is: ', e);
                    toast(`Error 3 downloading list - error is: ${JSON.stringify(e.message)}`);
                  }
                })
                .catch((error) => {
                  console.log('order request ERROR 3 - submitted values: ', values, ' | error is: ', error);
                  toast(`Error 4 downloading list - error is: ${JSON.stringify(error.message)}`);
                });
            })
            .catch(function getIdTokenError(error) {
              console.log('order request - getIdTokenError - error is: ', error);
            });
        }
      });
    }
  };

  formatTable() {
    const { state } = this;
    const { props } = this;
    const authUser = props && props.firebase && props.firebase.authUser;
    const { idToken } = state;
    const { OrdersList } = state;
    const length = OrdersList && OrdersList.length;
    if (length) {
      // console.log('OrdersList: ', OrdersList);
      const formatTableLevel = (depth = 0) => {
        return OrdersList.map((order) => {
          return {
            ...orderRecord(order, idToken, authUser),
            subRows: length[depth + 1] ? formatTableLevel(depth + 1) : undefined
          };
        });
      };
      return formatTableLevel();
    }
    return [];
  }

  render() {
    const { loading } = this.state;
    return (
      <>
        {loading && <div>Loading ...</div>}
        <TableStyles>
          <Table
            columns={columns}
            data={this.formatTable()}
          />
        </TableStyles>
      </>
    );
  }
}

export default withFirebase(OrdersAdmin);
